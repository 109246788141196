import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Test your IN-W1 USB Webcam on Windows and macOS",
  "path": "/Frequently_Asked_Question/IN-W1_USB_Webcam/",
  "dateChanged": "2020-04-29",
  "author": "Mike Polinowski",
  "excerpt": "How to find out if my INSTAR webcam is working in Windows and macOS?",
  "image": "./FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "Other"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='FAQs - Test your IN-W1 USB Webcam on Windows and macOS' dateChanged='2020-04-29' author='Mike Polinowski' tag='INSTAR IP Camera' description='How to find out if my INSTAR webcam is working in Windows and macOS?' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/IN-W1_USB_Webcam/' locationFR='/fr/Frequently_Asked_Question/IN-W1_USB_Webcam/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "test-your-in-w1-usb-webcam-on-windows-and-macos",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#test-your-in-w1-usb-webcam-on-windows-and-macos",
        "aria-label": "test your in w1 usb webcam on windows and macos permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Test your IN-W1 USB Webcam on Windows and macOS`}</h1>
    <p><strong parentName="p">{`Q`}</strong>{`: How to find out if my INSTAR webcam is working in Windows and macOS? `}</p>
    <p><strong parentName="p">{`A`}</strong>{`: Start by plugging in your camera to a free USB 2 port on your PC or Laptop.`}</p>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#anschluss-der-kamera"
        }}>{`Anschluss der Kamera`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#windows"
            }}>{`Windows`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#macos"
            }}>{`macOS`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#einstellen-der-lautst%C3%A4rke"
        }}>{`Einstellen der Lautstärke`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#was-tun-wenn-die-kamera-nicht-erkannt-wird"
        }}>{`Was tun wenn die Kamera nicht erkannt wird`}</a></li>
    </ul>
    {/* /TOC */}
    <h2 {...{
      "id": "windows",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#windows",
        "aria-label": "windows permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Windows`}</h2>
    <p>{`In Windows open the `}<strong parentName="p">{`Cortana Search`}</strong>{` dialogue and type in `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`camera`}</code>{`. Open the `}<strong parentName="p">{`Windows Camera App`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "820px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2cfc18e9d1bd583680c05bbd622af269/9f82e/IN-W1_USB_Webcam_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "81.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAAAsTAAALEwEAmpwYAAACzElEQVQ4y42TTW8TVxSG5990hxSI3VaQmJIK8U/4CV1UCCEhddEFZBEF1KRSN5VQF1UkqqqA6KIEgurgGSeWG9vYzjjjGX/Ml+0w4/l60L1OQiBB6pFenZlj3+e899w7yu7uLjeuX+PaNwUWFhZYWlqiUCiwuLgodeXyFXLzefK5HF/mc+TzeeZyc8xfvsDFxTnmFi4yf3Wer779mkuFSyiDwYDnTx/z97M/eb21KbX16oXUa5Ff/sN2sUi5XEbTNJmLJZXHm294uV2ivKOiljW0HY2SVkJxXIeHG8/QXjxC1zYY1p8wqP3FUKj+hP5/f5CGLqej6sAXd/7llzc+n4bieS7bahG9VsRqlejtqzJLtVXMZpHJyCbLII4TIKXjhtz6vcJmw5EQUU/TlCiKUHzfp17bwzBM9I4h5bg+o/EEzx9jOx5hGMqFWZaRyYeU6cQni6cndRFJkqCMRiOMbhdVVdkpl6nX6zSbb2m3WwwGfWzbJgiCD8AgkDlOUtI0m9WOFMcxyng8ptPp0Gq16Pf7CMee5+E4Dq7ryhwG7wijgLGuw+3bZIYxa5CmJ7AThwKwt7cngZZlcXBwgGmaJ2DhUGw5Cg9lw5Ftf3B7BPoIKBa1220ajQaVSkXCm82mlAAPh0PCIGAajBBXzPP9j4BnHB4DDcOgVqvJGYp3kUVt5jAgOPQwTUs2ECd62tmZLe/v71OtVtF1XUqARBYObTHDMGAaHjKZHJ4LOgMUcxOOBLjb7cp3MU/xm3B0fMqfQs7dslj0ttGgWqnIg+n1etJZvz/A8/zZKZ+6h+fFcXU2Q9ejYxi0un0M0+Sgo9OzLOzhUN5DyzKZjMckSUwcR59VEkeysRInEdGgzTt1Azxj1i8LIRNfwfQop/y/iFF+e/Qraz98z+rN6zy8+x0//7TC+uo91h/cZ/3B8pHus7Z6VrP/HD8vs7byI+8BLlp7iQlMtywAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2cfc18e9d1bd583680c05bbd622af269/e4706/IN-W1_USB_Webcam_01.avif 230w", "/en/static/2cfc18e9d1bd583680c05bbd622af269/d1af7/IN-W1_USB_Webcam_01.avif 460w", "/en/static/2cfc18e9d1bd583680c05bbd622af269/bbc6f/IN-W1_USB_Webcam_01.avif 820w"],
              "sizes": "(max-width: 820px) 100vw, 820px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2cfc18e9d1bd583680c05bbd622af269/a0b58/IN-W1_USB_Webcam_01.webp 230w", "/en/static/2cfc18e9d1bd583680c05bbd622af269/bc10c/IN-W1_USB_Webcam_01.webp 460w", "/en/static/2cfc18e9d1bd583680c05bbd622af269/b1f58/IN-W1_USB_Webcam_01.webp 820w"],
              "sizes": "(max-width: 820px) 100vw, 820px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2cfc18e9d1bd583680c05bbd622af269/81c8e/IN-W1_USB_Webcam_01.png 230w", "/en/static/2cfc18e9d1bd583680c05bbd622af269/08a84/IN-W1_USB_Webcam_01.png 460w", "/en/static/2cfc18e9d1bd583680c05bbd622af269/9f82e/IN-W1_USB_Webcam_01.png 820w"],
              "sizes": "(max-width: 820px) 100vw, 820px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2cfc18e9d1bd583680c05bbd622af269/9f82e/IN-W1_USB_Webcam_01.png",
              "alt": "Test your IN-W1 USB Webcam on Windows and macOS",
              "title": "Test your IN-W1 USB Webcam on Windows and macOS",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The  `}<strong parentName="p">{`Windows Camera App`}</strong>{` should automatically connect to your camera:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ed5ff7b86d66dfad1999dc07b7491eeb/7e11a/IN-W1_USB_Webcam_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.60869565217391%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC20lEQVQozy3PWVOaBxSAYfoPetFpR9u4IdUxURQTWZMBNVVGEIKFEATFIMsHnyCLLFqJNSaxEHYTK3ViAkmqTmt+4tsJ9OKdOedcPDNH0jeupn9chXRKi2xax9CkhkG5hgG5moEJFUNyNdLOTcXghArZlAaZQsPwpBqpXMnQxExn//a7H/hGIkESrF0Sqn4iUG7jK37A97qFv/yRzVKbQKlFsNwiXG0jVL7WIlT+QKB40SlSvkCsXJA4/czItBLJVzBaOkEsVEmUG8RLdWLFWmdOlhvsVBpkaydkqg3SlTrZap1spU6mXGOnVOmUKpXJ1k8YUyi64Mebc95fn9K6fsvnf8+4vGly9aXJ9Ze/uL5pdrq6afL3P2e0r97y7lOD83aN83adZqvO6fsa7y6bzKjvdcE3zQKNswK1P4sUakccVw45Lh9w/Hqf4+IeL//Y5ehVhsMXaZ6/zHFwlCX/e4a9Zxly+2my+xn2DvMMyYa7oNW9jtntw+hcw+KNYPPHWdkUsT0VcG4GcAkxXIEQYuQJu0kn21uP8a2bEHzL+L0mvO4l7I/0PMtnyeV2kWxEYwRCHuKJAKHtML5IkHUhiC8iEN4OE06IbKUixLZcBHxmjn5zk0+tkBUtvMq52Ik68Lrm8bjtWCxWJItWE3a3k4i4hiiukk25OcitkY1aOUhaSAQfEg/OEfUZKGRsvIibeZ4wkxSMpCJLhDyzpLZWGOj7vvvy1F0ZSs0YSp2C+/M6DMZ5/H4HuaSbmGAjvf2YfNpF1G8kGVgg8nSBjSc6PM77WJdncDxSYbdo6e39H5SN9iEd+ZHb8n4M+jto1LeZM0yg046w4dQhri8geI3EQkZsFi2mJQMOxwLplAfzohL9g0m8q7/w83BfF1Q/UDOtlDM2fovh0Z/Q6/tRTN9CNtqLTjPIpusedvMdZvVSHL9qmZ2bRKmZwrSsRvAuEvIssmp/iEp9l56eHv4D5CHQxXFGKxcAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ed5ff7b86d66dfad1999dc07b7491eeb/e4706/IN-W1_USB_Webcam_02.avif 230w", "/en/static/ed5ff7b86d66dfad1999dc07b7491eeb/d1af7/IN-W1_USB_Webcam_02.avif 460w", "/en/static/ed5ff7b86d66dfad1999dc07b7491eeb/7f308/IN-W1_USB_Webcam_02.avif 920w", "/en/static/ed5ff7b86d66dfad1999dc07b7491eeb/b7e6f/IN-W1_USB_Webcam_02.avif 1235w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ed5ff7b86d66dfad1999dc07b7491eeb/a0b58/IN-W1_USB_Webcam_02.webp 230w", "/en/static/ed5ff7b86d66dfad1999dc07b7491eeb/bc10c/IN-W1_USB_Webcam_02.webp 460w", "/en/static/ed5ff7b86d66dfad1999dc07b7491eeb/966d8/IN-W1_USB_Webcam_02.webp 920w", "/en/static/ed5ff7b86d66dfad1999dc07b7491eeb/9c746/IN-W1_USB_Webcam_02.webp 1235w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ed5ff7b86d66dfad1999dc07b7491eeb/81c8e/IN-W1_USB_Webcam_02.png 230w", "/en/static/ed5ff7b86d66dfad1999dc07b7491eeb/08a84/IN-W1_USB_Webcam_02.png 460w", "/en/static/ed5ff7b86d66dfad1999dc07b7491eeb/c0255/IN-W1_USB_Webcam_02.png 920w", "/en/static/ed5ff7b86d66dfad1999dc07b7491eeb/7e11a/IN-W1_USB_Webcam_02.png 1235w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ed5ff7b86d66dfad1999dc07b7491eeb/c0255/IN-W1_USB_Webcam_02.png",
              "alt": "Test your IN-W1 USB Webcam on Windows and macOS",
              "title": "Test your IN-W1 USB Webcam on Windows and macOS",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "macos",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#macos",
        "aria-label": "macos permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`macOS`}</h2>
    <p>{`Click the `}<strong parentName="p">{`Go`}</strong>{` menu. It’s in the menu bar at the top of the screen:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "759px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/5255d713db5f5a697aa99b423d85e669/ef3e1/IN-W1_USB_Webcam_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "57.391304347826086%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC3UlEQVQozzXMS1MaBwDA8f1kvXXaadoOaaNN6hNn4ososqzgQvABmmomifjA1lKJrTECGwRBeezCsuwuLkHMOJnpTDq95dBe+h3+naTTw+/6EzaTOk9+rvNot8zTZIP4Lw2eJOtspppsH9RZiNcJbtWoVqqoqoquN1E1DefSJl+22Ht5xX76ip8+yHQR7ngdbnvb9Pkd+iSHPt8l/fMO9xYcXHMOiV8tupcNtHoD27b/077ENDQ2D3Rc/jfckXvcXujhCl0hjAQdRhccxsKXjMo2w4E27lCbsXCbe5LJznMDTS2jKDkK+QLFUomm0aJ8UWD7eYMBuYd7qcvIcpfRpS7CkGgxErQZlk0GpNZHQ8Emo3KLu/4mOymd8nmewlmRQqGApmm0TItSMcd2qs5w6IqJxQ/eMBm5RhgINHEvmEzIHSZkh/Gwzf2QxXjIYkQy2Ts0OMtnOUlnyGYV9EaDVstEyR6TSNWYXekhxbpMBZpMBhoIUtTBF+0iLl/ji/TwRl4zs9xhdqXD5MPX7P9mkUkfkf4/1HVM0+I0l+HwWGf12VuijztEVi1CSwaCP2wSXLSRwhb+iI0Y6yKu9RDXr3kQuyb5wuYsr5DOZKnVVNrtNobRQlHSvDiqEn9ss7/d4WCvQzJhInhkA2/EZlZu4hGrTPkqeCSVBwGNSb9OMtXg4jxPJpOlVCx+DFuGgaJkyB4WST0q8IP8FO+4iOyZQpj2FJmeOWfWW2bOV0b0V5ifrxCYr+DzVUgeGZye5Tg+yZB9laNl2+iGwckrhZOXCpEZicHvv6Pv268ITN1F2IyqbMVUtlc1dtY0EtEaP8aqJDc0UlsWN2KMP4MSN6KfG98c72SZ92urvBNFCt+4+NJ1iyl3P+vSIDH/IEL2WZXTeI3SrsrZrkZhV6OU0MjtXHCwkeXt2DR/r67we0jmj4dh/tpY55+tOO8XI6iff0p/39eI7n7uD33BZ7c+4V8lW2fCr07JHQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5255d713db5f5a697aa99b423d85e669/e4706/IN-W1_USB_Webcam_03.avif 230w", "/en/static/5255d713db5f5a697aa99b423d85e669/d1af7/IN-W1_USB_Webcam_03.avif 460w", "/en/static/5255d713db5f5a697aa99b423d85e669/f9450/IN-W1_USB_Webcam_03.avif 759w"],
              "sizes": "(max-width: 759px) 100vw, 759px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/5255d713db5f5a697aa99b423d85e669/a0b58/IN-W1_USB_Webcam_03.webp 230w", "/en/static/5255d713db5f5a697aa99b423d85e669/bc10c/IN-W1_USB_Webcam_03.webp 460w", "/en/static/5255d713db5f5a697aa99b423d85e669/3440c/IN-W1_USB_Webcam_03.webp 759w"],
              "sizes": "(max-width: 759px) 100vw, 759px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5255d713db5f5a697aa99b423d85e669/81c8e/IN-W1_USB_Webcam_03.png 230w", "/en/static/5255d713db5f5a697aa99b423d85e669/08a84/IN-W1_USB_Webcam_03.png 460w", "/en/static/5255d713db5f5a697aa99b423d85e669/ef3e1/IN-W1_USB_Webcam_03.png 759w"],
              "sizes": "(max-width: 759px) 100vw, 759px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/5255d713db5f5a697aa99b423d85e669/ef3e1/IN-W1_USB_Webcam_03.png",
              "alt": "Test your IN-W1 USB Webcam on Windows and macOS",
              "title": "Test your IN-W1 USB Webcam on Windows and macOS",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Click `}<strong parentName="p">{`Applications`}</strong>{` menu. A list of programs on your Mac will appear:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "759px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/1db8d58a9415f97dd63e832e7de401c9/ef3e1/IN-W1_USB_Webcam_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "32.173913043478265%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAAsTAAALEwEAmpwYAAABjUlEQVQY0zWRS2sTYRSG83u68Q9YF+5FKKgFQXFXuigUqfWysOAl0JVLq500TdLSGRqo1CQlMxPNZNLMfJdJL5kk1Y2bUlvoTsFF4ZGvIYvDgXM4z3nPezJSSpIkwXPrbG/Z7DhfEEKglEQqjRQhorWOCDdRQhL3ehwXc/x7+ZzL5WUu3r3l74tF0s0S0eEhmTiOOU77vH+1wIM7N7j1ZBLfbdDtJkiVELVrBPWPhN93UEIQ9XqcFHOcF4pkpx/yYWaWy/I2P0oFOmPgcJAy9+wNE3fvc3vmJn7dALsY9VG0TyvsEIZtZNS5Bg438lysWmQfP2J66h7+6yV+OVvsdw/IKKUw0HK5zNpanpK1QTMI0EohTYiYvdouvreHEjFx2ie1VvjT8Pl9dcXR2RnnX3c5KeRHCrXWNJtNqpUKQRDgei6e66G1ulZoFlarNRqNb2g58jBdtzhdmGdofeJn7jOni08ZlgojD82QgZoTTTYPMjGGmWx645rUGhWG9G2bgWMzsG36joNutxFS8h+2m3xHOYvZxQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1db8d58a9415f97dd63e832e7de401c9/e4706/IN-W1_USB_Webcam_04.avif 230w", "/en/static/1db8d58a9415f97dd63e832e7de401c9/d1af7/IN-W1_USB_Webcam_04.avif 460w", "/en/static/1db8d58a9415f97dd63e832e7de401c9/f9450/IN-W1_USB_Webcam_04.avif 759w"],
              "sizes": "(max-width: 759px) 100vw, 759px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/1db8d58a9415f97dd63e832e7de401c9/a0b58/IN-W1_USB_Webcam_04.webp 230w", "/en/static/1db8d58a9415f97dd63e832e7de401c9/bc10c/IN-W1_USB_Webcam_04.webp 460w", "/en/static/1db8d58a9415f97dd63e832e7de401c9/3440c/IN-W1_USB_Webcam_04.webp 759w"],
              "sizes": "(max-width: 759px) 100vw, 759px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1db8d58a9415f97dd63e832e7de401c9/81c8e/IN-W1_USB_Webcam_04.png 230w", "/en/static/1db8d58a9415f97dd63e832e7de401c9/08a84/IN-W1_USB_Webcam_04.png 460w", "/en/static/1db8d58a9415f97dd63e832e7de401c9/ef3e1/IN-W1_USB_Webcam_04.png 759w"],
              "sizes": "(max-width: 759px) 100vw, 759px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/1db8d58a9415f97dd63e832e7de401c9/ef3e1/IN-W1_USB_Webcam_04.png",
              "alt": "Test your IN-W1 USB Webcam on Windows and macOS",
              "title": "Test your IN-W1 USB Webcam on Windows and macOS",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Double-click `}<strong parentName="p">{`Photobooth`}</strong>{`. The program will automatically open to your webcam. If you can see your webcam's image, it is working properly.`}</p>
    <h2 {...{
      "id": "adjusting-the-volume",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#adjusting-the-volume",
        "aria-label": "adjusting the volume permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Adjusting the volume`}</h2>
    <p>{`If you do not hear sound in your video recordings, it is possible that the audio input has been muted in Windows. To fix this, please open the Windows settings and search for `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Sound`}</code>{`. You should find the camera input with displayed audio levels:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/dd92bba017f3cf6e510d486cea9b4c00/eb3fa/IN-W1_USB_Webcam_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "78.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAAAsTAAALEwEAmpwYAAACjUlEQVQ4y5WUa0/bSBSG8/9/Al/4QCClgVwpvUHr3GzHSXC26m4Xughpl0uc+BbbYyckzrPyQNMFsivtSK88lnwenXfmvM5tbW2xvb1NPp8nn9+Rz52dHa6urgjDENd18TxvrWkQEcQzAvGox70XCtwgInd4WKJ4cECtVqd+dCRVq9exLIsgCFgul/yflXvf7KKav6F/+Z3+r38w+HZJe/CVP69vmEzGzOdz0jRdF6xWq/9U7sPHj7RVFVXTUTWNjqpKZR3e3d0xGo3kPkkS7u/vZdE/1/P3nGEYqGoH0zQZmiamecZg0Me2bRzXZTKZrOV7HiIKJTwSgsVi8QKaa3Y0CRz+YjIc/gQ6jk3g+3iug+/7TKdT/EjgRRHTMCQWYn2+T4CZzVarRbvTQtM6Et7vGUxcj5EvuHUj4tn9Q+HbIquTKlx+gzjabLlr9FAUBaWhoCif6XZ1zs76jCc2biCIkvn649S2HhQL0sXiEbZ62mFv8AVFadJsKDQan2m3W/T7Bre3N5xffOfy+wUX5+fSdnbX2aktVytp9wfoCdDof0XrnqHrOqraptfrYhg6Y8eVlm/sKZYbkKbLjRZfWO5oQ5Rml9NPDU4/naLrmoTajkMgEkKpWKbmefFG4IeTNrU3J1Tq73j3/gRFadBsteQMjscWVjaHj7PoOA83no1LNuwbLZcqbynsVyge1qhUs+gdU63WZYeLxVIC4iSRiRFCSM1mM6mNwFeviuzu7ZPfLbC7V6BULpPle2xZzJNYDrKUEBuz+8JyoZCB9qRevy5SrlQol8tc34258WKunZC/7JCJH23M7ssOD8rsH1bYL1UpHx1Tqh9Tqr1hZHuEC3CTlOl8RfovHT3/2/wNlfacmUsweccAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/dd92bba017f3cf6e510d486cea9b4c00/e4706/IN-W1_USB_Webcam_05.avif 230w", "/en/static/dd92bba017f3cf6e510d486cea9b4c00/d1af7/IN-W1_USB_Webcam_05.avif 460w", "/en/static/dd92bba017f3cf6e510d486cea9b4c00/7f308/IN-W1_USB_Webcam_05.avif 920w", "/en/static/dd92bba017f3cf6e510d486cea9b4c00/b248f/IN-W1_USB_Webcam_05.avif 1026w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/dd92bba017f3cf6e510d486cea9b4c00/a0b58/IN-W1_USB_Webcam_05.webp 230w", "/en/static/dd92bba017f3cf6e510d486cea9b4c00/bc10c/IN-W1_USB_Webcam_05.webp 460w", "/en/static/dd92bba017f3cf6e510d486cea9b4c00/966d8/IN-W1_USB_Webcam_05.webp 920w", "/en/static/dd92bba017f3cf6e510d486cea9b4c00/1dc52/IN-W1_USB_Webcam_05.webp 1026w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/dd92bba017f3cf6e510d486cea9b4c00/81c8e/IN-W1_USB_Webcam_05.png 230w", "/en/static/dd92bba017f3cf6e510d486cea9b4c00/08a84/IN-W1_USB_Webcam_05.png 460w", "/en/static/dd92bba017f3cf6e510d486cea9b4c00/c0255/IN-W1_USB_Webcam_05.png 920w", "/en/static/dd92bba017f3cf6e510d486cea9b4c00/eb3fa/IN-W1_USB_Webcam_05.png 1026w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/dd92bba017f3cf6e510d486cea9b4c00/c0255/IN-W1_USB_Webcam_05.png",
              "alt": "Test your IN-W1 USB Webcam on Windows and macOS",
              "title": "Test your IN-W1 USB Webcam on Windows and macOS",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`If the latter does not react when you speak into your camera, please click on `}<strong parentName="p">{`Device Properties`}</strong>{`. Check that the microphone is not deactivated and adjust the volume according to your needs:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "858px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/fdbd88b76ebc7bed141d00c9975c8057/42d54/IN-W1_USB_Webcam_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "93.91304347826086%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAYAAACQjC21AAAACXBIWXMAAAsTAAALEwEAmpwYAAACfklEQVQ4y4WUS2sTURzF78wkJtJQcKG4EPxIDZGahQs3BcGNH0DcCC4EKaXtJiLU1n3TxC/SRVukdFU1887MZGbuzJ3XkXuTDHmaC4d53fubc/73QZ48e45qtQpJViArCmR5IqUCIsmo1R/i8OgYvX4fp2c/cN7totf/iYteX1y7Fz2cdy9KkUePn4IQslb1eh13d3fgjVKKLMswbWmaYrGR2tY2ZIlAkeWxFEWoUqlAkiQ0Gg1cXl6KwYZpQlVVIcuyMBgMEASBABVFIX5G/uduKg6M4xiGYcD3fSRJAsaYAJimiaurK1xfX2M0GoHstHbR3H2Fl3vvsNN+jWbzBVotrhaazSba7bbozJ1wYBRFwk2e50Iczr9xCYd/VB22ZcMZOnCHDjzXnasJH8RdcEcsScSziDiJuVRDVTMwtG2EIUUUM7h+CEoj0Cguo/F6WZYJz3Umw+ZBHDwV8fwQLGYCFtAY904k6jQcURQYR7NtCz6NMYoyBCwfKymgejHiKCqhwmEQxVjXpp34bP66vYVuWsgKIM0KpDkwDJlIMA+k8ZLtqabvbduGpmlwy8jzsWdruRHII488b9Z2CVv88RJwVVwO0HQDv1UdfEUMdBP3f1UY9nAJutHheL1lsBwPVpjCoSnsMIXhJ+J+JdALQuEiy3IURT6Os+BW1zW4QQSXAUGSr6xdCcxnHlgB2FEOmuRiRzA23gV8HSZpDpYVSLLlSZtzuPfmLd5/+IivnQ729/fx6fMXHB0eoHN8gNOTbzj7fgJNVWcmZLWzErjpYKjVHuDm5kZ05nt1nbMSWG9sQyJEHFVchItM7ifnIT8cFoFrHVbqWyudrQNuav8AatDyt8KY9FUAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fdbd88b76ebc7bed141d00c9975c8057/e4706/IN-W1_USB_Webcam_06.avif 230w", "/en/static/fdbd88b76ebc7bed141d00c9975c8057/d1af7/IN-W1_USB_Webcam_06.avif 460w", "/en/static/fdbd88b76ebc7bed141d00c9975c8057/1f8f9/IN-W1_USB_Webcam_06.avif 858w"],
              "sizes": "(max-width: 858px) 100vw, 858px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/fdbd88b76ebc7bed141d00c9975c8057/a0b58/IN-W1_USB_Webcam_06.webp 230w", "/en/static/fdbd88b76ebc7bed141d00c9975c8057/bc10c/IN-W1_USB_Webcam_06.webp 460w", "/en/static/fdbd88b76ebc7bed141d00c9975c8057/41e9e/IN-W1_USB_Webcam_06.webp 858w"],
              "sizes": "(max-width: 858px) 100vw, 858px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fdbd88b76ebc7bed141d00c9975c8057/81c8e/IN-W1_USB_Webcam_06.png 230w", "/en/static/fdbd88b76ebc7bed141d00c9975c8057/08a84/IN-W1_USB_Webcam_06.png 460w", "/en/static/fdbd88b76ebc7bed141d00c9975c8057/42d54/IN-W1_USB_Webcam_06.png 858w"],
              "sizes": "(max-width: 858px) 100vw, 858px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/fdbd88b76ebc7bed141d00c9975c8057/42d54/IN-W1_USB_Webcam_06.png",
              "alt": "Test your IN-W1 USB Webcam on Windows and macOS",
              "title": "Test your IN-W1 USB Webcam on Windows and macOS",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "what-to-do-if-the-camera-is-not-recognized",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#what-to-do-if-the-camera-is-not-recognized",
        "aria-label": "what to do if the camera is not recognized permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What to do if the camera is not recognized`}</h2>
    <p>{`Windows allows you to deny programs access to the webcam. If you cannot access the camera's video or audio signal, search for `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`private`}</code>{` or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`privacy`}</code>{` in the Windows settings and select the appropriate camera setting:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e236e29ac0e293655ca172982b0bacbc/d326d/IN-W1_USB_Webcam_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "23.91304347826087%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAs0lEQVQY04WLTQuCQBRF33szY8qURA6jG4uoRUsLUtzVro06tQ/q76Ruqk31Z6NPCIQO3Ls59wIiQhP4odGyJw2CMyal9INAa+1r3ZaSEcFf6DlaLRfX6nCpymtdnevydjoms+nX/iAQOedEhPhIC2EyHm3zfF+Y3XpTZJkxJgxDALAZ2YJbliWEeJ8lI+V5tuMIIQiha/HhoB8ncZym80elURQppQCgw6nXaXtKua77Ot8B21cWJEdv0osAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e236e29ac0e293655ca172982b0bacbc/e4706/IN-W1_USB_Webcam_07.avif 230w", "/en/static/e236e29ac0e293655ca172982b0bacbc/d1af7/IN-W1_USB_Webcam_07.avif 460w", "/en/static/e236e29ac0e293655ca172982b0bacbc/7f308/IN-W1_USB_Webcam_07.avif 920w", "/en/static/e236e29ac0e293655ca172982b0bacbc/e22f7/IN-W1_USB_Webcam_07.avif 1197w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e236e29ac0e293655ca172982b0bacbc/a0b58/IN-W1_USB_Webcam_07.webp 230w", "/en/static/e236e29ac0e293655ca172982b0bacbc/bc10c/IN-W1_USB_Webcam_07.webp 460w", "/en/static/e236e29ac0e293655ca172982b0bacbc/966d8/IN-W1_USB_Webcam_07.webp 920w", "/en/static/e236e29ac0e293655ca172982b0bacbc/760a1/IN-W1_USB_Webcam_07.webp 1197w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e236e29ac0e293655ca172982b0bacbc/81c8e/IN-W1_USB_Webcam_07.png 230w", "/en/static/e236e29ac0e293655ca172982b0bacbc/08a84/IN-W1_USB_Webcam_07.png 460w", "/en/static/e236e29ac0e293655ca172982b0bacbc/c0255/IN-W1_USB_Webcam_07.png 920w", "/en/static/e236e29ac0e293655ca172982b0bacbc/d326d/IN-W1_USB_Webcam_07.png 1197w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e236e29ac0e293655ca172982b0bacbc/c0255/IN-W1_USB_Webcam_07.png",
              "alt": "Test your IN-W1 USB Webcam on Windows and macOS",
              "title": "Test your IN-W1 USB Webcam on Windows and macOS",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Make sure that both the `}<strong parentName="p">{`camera`}</strong>{` and the `}<strong parentName="p">{`microphone`}</strong>{` are enabled:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e343fd72cd9ebdd36faf6c62c5fdb06c/9a1cf/IN-W1_USB_Webcam_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "49.130434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABk0lEQVQoz32Rz07bQBCHvescQryzWIlt5Hj/jLcJtmMTQm68QuW3yDGncoKHoCfUPkYiRXkCFAonDkjlCTj1WgkRKTLCpbQg2u883/xG87PSNEVE/RtjjJSy0WgQQiiltm0TQqx/kSRJnudaa0SM47gbhs1mk1LaarUopdb/6ff7WZYZY5RSnud9Ojo6/3Y5m80Wi8V8Pl8ul2VZMsbCMIyiiDFGap7l8XicJAki9no9Pwi+np1V9z/XDw9VVW02m6qqptOp4zhCiG63G4ah7/u2bT/LWBPXN3d8/8vn0+rH3Xq9fpEnkwkhpN1uu67b6XQ453+SB4N8dDAyu7sxYhAExycn1zc3l1dX17ffVxcXq9WqLEvLst5/Wz7IRsPhAcaRVqi1EAIA2gCTLIetrSftr+G3KwZpWhRFaT4USkutTRxLpVyAj/6O57p8e5tzDgCMsXeS0zTNhsPDLDvUGNWFCSGCINhBlFJGUSSEkFIKIbwazvkrucjzvf19bYxW6pfsOA5nDAA456wGANwazvlL/4/vAmGBBXrLMgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e343fd72cd9ebdd36faf6c62c5fdb06c/e4706/IN-W1_USB_Webcam_08.avif 230w", "/en/static/e343fd72cd9ebdd36faf6c62c5fdb06c/d1af7/IN-W1_USB_Webcam_08.avif 460w", "/en/static/e343fd72cd9ebdd36faf6c62c5fdb06c/7f308/IN-W1_USB_Webcam_08.avif 920w", "/en/static/e343fd72cd9ebdd36faf6c62c5fdb06c/e89ec/IN-W1_USB_Webcam_08.avif 924w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e343fd72cd9ebdd36faf6c62c5fdb06c/a0b58/IN-W1_USB_Webcam_08.webp 230w", "/en/static/e343fd72cd9ebdd36faf6c62c5fdb06c/bc10c/IN-W1_USB_Webcam_08.webp 460w", "/en/static/e343fd72cd9ebdd36faf6c62c5fdb06c/966d8/IN-W1_USB_Webcam_08.webp 920w", "/en/static/e343fd72cd9ebdd36faf6c62c5fdb06c/c3b05/IN-W1_USB_Webcam_08.webp 924w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e343fd72cd9ebdd36faf6c62c5fdb06c/81c8e/IN-W1_USB_Webcam_08.png 230w", "/en/static/e343fd72cd9ebdd36faf6c62c5fdb06c/08a84/IN-W1_USB_Webcam_08.png 460w", "/en/static/e343fd72cd9ebdd36faf6c62c5fdb06c/c0255/IN-W1_USB_Webcam_08.png 920w", "/en/static/e343fd72cd9ebdd36faf6c62c5fdb06c/9a1cf/IN-W1_USB_Webcam_08.png 924w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e343fd72cd9ebdd36faf6c62c5fdb06c/c0255/IN-W1_USB_Webcam_08.png",
              "alt": "Test your IN-W1 USB Webcam on Windows and macOS",
              "title": "Test your IN-W1 USB Webcam on Windows and macOS",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`If you scroll down a bit you will find a per-program control. Make sure that the program you want to use is active:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "857px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/de4a9770590bfd6101cb5b08ff09ca7c/3cd52/IN-W1_USB_Webcam_09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "76.95652173913044%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACK0lEQVQoz32T2Y6bMBSGIQuLhyFgFrM406kS8EZwbJa0mnZy15s+Qy/7BpX6+hWEjKpW7ScL2ZY+/cc+xjjr/qw0F+LUNIJzSgilNM8L4475b4xWqm64SHlmjFFKq6qu6xohhFFyeP/sAmD8h9OpbeVZaU0pqar6cDhQStIsv2bBESWGubqFG6ZpGOZbIYvc9/0wjlxMMMbJXHaWZUmaPs9gjPdlWSZRCsMgDIMgiKLIsqxJbprm1LaMMsE5Y+xWdpZl1ozjOJ7n2dbWWa+slfl32aezUn3faa0oIdVxsjHGcRzfYuM4jqIohDCKJyCEvu/f5bbV3WRyRigh9bG+yRDCoigQQg8zAICH39hut0uy1lpKSUhN6ro6HimlGOMQRkVR5nmOMS7LZYLnBS7L5cxSymEYx/Ey52utO875fr8PPDeC4S4IIITRnTiOgzD0QrherydZKTWM48unz9fr9fX1VU2yeNpj++kMYO5Y26VV96+7MtrId113kttW6q67fPg4jKNSuq4JZRwXeXD9Hlc6Ch4fH33f9z3PA7Pgrlcy2TmOM5956pTsh1EpxRifBhcFSoyXH+Cd3gHrdsu2bd8ueGPbdhgty+lxTK2WTSME5/MbYUmKvsruWJSu97CE3AEr45Lu3spuu67Tfc9nGGWMsThF35SgKLZc4P4hr80+2S2bo1L9OJ67jlAmhOCi4aLJ03jz5SfkL2UKU4QAAJvN5p5sDigE8w/zC/89VZokF3haAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/de4a9770590bfd6101cb5b08ff09ca7c/e4706/IN-W1_USB_Webcam_09.avif 230w", "/en/static/de4a9770590bfd6101cb5b08ff09ca7c/d1af7/IN-W1_USB_Webcam_09.avif 460w", "/en/static/de4a9770590bfd6101cb5b08ff09ca7c/ab1cf/IN-W1_USB_Webcam_09.avif 857w"],
              "sizes": "(max-width: 857px) 100vw, 857px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/de4a9770590bfd6101cb5b08ff09ca7c/a0b58/IN-W1_USB_Webcam_09.webp 230w", "/en/static/de4a9770590bfd6101cb5b08ff09ca7c/bc10c/IN-W1_USB_Webcam_09.webp 460w", "/en/static/de4a9770590bfd6101cb5b08ff09ca7c/a145c/IN-W1_USB_Webcam_09.webp 857w"],
              "sizes": "(max-width: 857px) 100vw, 857px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/de4a9770590bfd6101cb5b08ff09ca7c/81c8e/IN-W1_USB_Webcam_09.png 230w", "/en/static/de4a9770590bfd6101cb5b08ff09ca7c/08a84/IN-W1_USB_Webcam_09.png 460w", "/en/static/de4a9770590bfd6101cb5b08ff09ca7c/3cd52/IN-W1_USB_Webcam_09.png 857w"],
              "sizes": "(max-width: 857px) 100vw, 857px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/de4a9770590bfd6101cb5b08ff09ca7c/3cd52/IN-W1_USB_Webcam_09.png",
              "alt": "Test your IN-W1 USB Webcam on Windows and macOS",
              "title": "Test your IN-W1 USB Webcam on Windows and macOS",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      